<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="categoryColumns"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="categoryColumnsPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Category Columns</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('category_column_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Category Column
                  </v-btn>
                </template>
                <v-card :class="customClass">
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Name -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedCategoryColumn.name"
                              :rules="nameRules"
                              label="Name"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Name <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- Companies -->
                          <v-col
                            v-if="$can('super_admin')"
                            class="mt-n5"
                            cols="12"
                          >
                            <v-select
                              v-model="companyName"
                              :items="companyNamesList"
                              :rules="companyNameRules"
                              attach
                              label="Companies"
                            >
                              <template v-slot:label>
                                <p class="body-1">Company</p>
                              </template>
                            </v-select>
                          </v-col>

                          <!-- Ative -->
                          <!-- <v-col class="mt-n5" cols="12">
                            <v-checkbox
                              v-model="editedCategoryColumn.active"
                              :label="`Active`"
                            ></v-checkbox>
                          </v-col> -->
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Are you sure you want to delete this category column?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="btn btn-primary"
                      @click="deleteCategoryColumnConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- active -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.active="{ item }">
            <v-chip
              v-if="item.active"
              class="ma-2"
              color="green"
              text-color="white"
            >
              Yes
            </v-chip>

            <v-chip v-else class="ma-2" color="red" text-color="white">
              No
            </v-chip>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- updated_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.updated_at="{ item }">
            {{ $helpers.datetime(item.updated_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('category_column_update')"
              small
              class="mr-2"
              @click="editCategoryColumn(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('category_column_delete')"
              small
              @click="deleteCategoryColumn(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Category Columns per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementCategoryColumns",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "Name", value: "name" },
        { text: "Company", value: "company_id" },
        // { text: "Active", value: "active" },
        { text: "Created At", value: "created_at" },
        { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      categoryColumns: [],

      companies: [],
      companyName: "",
      companyNamesList: [],

      editedIndex: -1,
      editedCategoryColumn: {
        id: 0,
        name: "",
        // active: true,
        company_id: 0,
        created_at: "",
        updated_at: "",
      },
      defaultCategoryColumn: {
        id: 0,
        name: "",
        // active: true,
        company_id: 0,
        created_at: "",
        updated_at: "",
      },

      // name
      nameRules: [
        (v) => !!v || "Required",
        (v) =>
          /^[a-zA-Z][a-zA-Z0-9 \-']+$/.test(v) ||
          "Only english letters, apostrophes and hyphens are allowed",
        (v) => v.length >= 3 || "Can not be less than 3 characters",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      // companyName
      companyNameRules: [],

      page: 1,
      categoryColumnsPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],

      customClass: "",
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1
        ? "New Category Column"
        : "Edit Category Column";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedCategoryColumns();
    },

    // categoryColumnsPerPage
    categoryColumnsPerPage() {
      this.getApiPagedCategoryColumns();
    },

    // perPageChoice
    perPageChoice(val) {
      this.categoryColumnsPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("category_column_access")) {
      this.$router.push({ name: "DashboardInbox" });
    }

    // this.getApiCategoryColumns();
    this.getApiPagedCategoryColumns();

    if (this.$can("super_admin")) {
      this.getApiCompanies();

      // customClass
      this.customClass = "padding-bottom";
    }
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiCategoryColumn();
        } else {
          this.createApiCategoryColumn();
        }
      }
    },

    // editCategoryColumn
    editCategoryColumn(categoryColumn) {
      this.editedIndex = this.categoryColumns.indexOf(categoryColumn);
      this.editedCategoryColumn = Object.assign({}, categoryColumn);
      this.dialog = true;

      if (this.editedIndex != -1) {
        this.companyName = this.$helpers.getNameByIdFromList(
          this.companies,
          categoryColumn.company_id
        );
      }
    },

    // deleteCategoryColumn
    deleteCategoryColumn(categoryColumn) {
      this.editedIndex = this.categoryColumns.indexOf(categoryColumn);
      this.editedCategoryColumn = Object.assign({}, categoryColumn);
      this.dialogDelete = true;
    },

    // deleteCategoryColumnConfirm
    deleteCategoryColumnConfirm() {
      this.deleteApiCategoryColumn();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedCategoryColumn = Object.assign(
          {},
          this.defaultCategoryColumn
        );
        this.editedIndex = -1;
        this.companyName = "";
      });

      this.$refs.form.resetValidation();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedCategoryColumn = Object.assign(
          {},
          this.defaultCategoryColumn
        );
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // getApiCategoryColumns
    async getApiCategoryColumns() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("category-columns");

      if (res.status == 200) {
        this.categoryColumns = res.data;
      }

      this.overlay = false;
    },

    // getApiPagedCategoryColumns
    async getApiPagedCategoryColumns() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `category-columns/paged?page_no=${this.page}&page_size=${this.categoryColumnsPerPage}`
      );

      if (res.status == 200) {
        this.categoryColumns = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // createApiCategoryColumn
    async createApiCategoryColumn() {
      this.overlay = true;

      var reqData = {
        name: this.editedCategoryColumn.name,
        // active: this.editedCategoryColumn.active,
        company_id: this.auth.user.company_id,
      };

      if (this.companyName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.companies,
          this.companyName
        );

        if (id > 0) {
          reqData.company_id = id;
        }
      }

      const res = await this.$helpers.createApiData(
        "category-columns",
        reqData,
        "Category Column Created Successfully!"
      );

      if (res.status == 200) {
        this.categoryColumns.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // updateApiCategoryColumn
    async updateApiCategoryColumn() {
      this.overlay = true;

      var reqData = {
        id: this.editedCategoryColumn.id,
        name: this.editedCategoryColumn.name,
        // active: this.editedCategoryColumn.active,
        company_id: this.auth.user.company_id,
      };

      if (this.companyName != "") {
        var id = this.$helpers.getIdByNameFromList(
          this.companies,
          this.companyName
        );

        if (id > 0) {
          reqData.company_id = id;
        }
      }

      const res = await this.$helpers.updateApiData(
        `category-columns/${this.editedCategoryColumn.id}`,
        reqData,
        "Category Column Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.categoryColumns[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // deleteApiCategoryColumn
    async deleteApiCategoryColumn() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `category-columns/${this.editedCategoryColumn.id}`,
        "Category Column Deleted Successfully!"
      );

      if (res.status == 200) {
        this.categoryColumns.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },

    // Get Api Companies
    async getApiCompanies() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("companies");

      if (res.status == 200) {
        this.companies = res.data;

        this.companyNamesList = this.$helpers.getNamesFromList(this.companies);
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.padding-bottom {
  padding-bottom: 180px;
}
</style>
