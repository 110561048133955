<template>
  <div class="background">
    <CategoryColumns />
  </div>
</template>

<script>
import CategoryColumns from "@/components/elements/dashboard/category-columns/CategoryColumns";

export default {
  name: "DocumentManagementCategoryColumns",

  components: {
    CategoryColumns,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
